<template>
  <spinner-layout :loading="loading">
    <m-modal
      modal-title=""
      body-title="BodyTitle"
      :open="showModal"
      @update:open="showModal = !showModal"
    >
      <template v-slot:default>
        <h5 class="mt-heading mt-heading--s mt-heading--underline mt-heading--light">
          {{ storeSelected.name }}
        </h5>
        <div class="container_outlined mu-p-050">
          <!-- ADDRESS -->
          <div class="mu-mb-100 icon_text">
            <m-icon name="MonochromeStoreLocation64" />
            <div>
              <h4>
                {{ $t('ui.label.store.address') }}
              </h4>
              <p v-if="storeSelected.ourStore || storeSelected.street">
                {{ address(storeSelected) }}
              </p>
            </div>
          </div>

          <!-- CITY - PROVINCE -->
          <div v-if="storeSelected.city || storeSelected.province" class="mu-mt-100 icon_text">
            <m-icon name="Locator64" />
            <div>
              <h4>
                {{ $t('ui.label.store.cityProvince') }}
              </h4>
              <p class="mc-label__text">
                {{ cityProvince(storeSelected) }}
              </p>
            </div>
          </div>

          <!-- COMMUNITY -->
          <div v-if="storeSelected.comunity" class="mu-mt-100 icon_text">
            <m-icon name="Map64" />
            <div>
              <h4>
                {{ $t('ui.label.store.community') }}
              </h4>
              <p class="mc-label__text">
                {{ storeSelected.comunity }}
              </p>
            </div>
          </div>

          <!-- PHONE -->
          <div v-if="storeSelected.professionalPhone" class="mu-mt-100 icon_text">
            <m-icon name="ContactPhone48" />
            <div>
              <h4>{{ $t('ui.label.store.phone') }}</h4>
              <p class="mc-label__text">{{ storeSelected.professionalPhone }}</p>
            </div>
          </div>

          <!-- URL -->
          <div v-if="storeSelected.url" class="mu-mt-100 icon_text">
            <m-icon name="ContactWebsite48" />
            <div>
              <m-link :href="storeSelected.url">
                <template v-slot:default>
                  {{ $t('ui.label.store.moreInfo') }}
                </template>
              </m-link>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <m-button size="s" :label="$t('ui.button.btnSelect')" @click="selectStore(storeSelected)" />
        <m-button
          size="s"
          theme="bordered-neutral"
          :label="$t('ui.button.btnClose')"
          @click="showModal = !showModal"
        />
      </template>
    </m-modal>

    <div v-if="storesOrderedByCommunity.length > 0">

      <!-- SI BUSCAMOS STOCK DE UNA REFERENCIA EN TODAS LAS TIENDAS, MOSTRAMOS INFORMACIÓN DEL PRODUCTO -->
      <h5 v-if="productName" class="mt-heading mt-heading--s mt-heading--center margin-bottom-zero">
        {{ productName }}
      </h5>
      <h5 v-else class="mt-heading mt-heading--s mt-heading--center mt-heading--underline">
        {{ $t('ui.label.store.select') }}
      </h5>

      <!-- REFERENCE AND LAST UPDATE DATE -->
      <h5 v-if="productReference" class="mt-heading--center mt-heading--underline sku-date-info">
        {{ `Ref. ${productReference}` }} - {{ productDate }}
      </h5>

      <!-- DESPLEGABLE DE COMUNIDADES AUTÓNOMAS -->
      <m-accordion
        v-for="(item, index) in storesOrderedByCommunity"
        :id="`store-${index}`"
        :key="index"
        :label="item.comunity"
      >
        <template v-slot:default>
          <div
            v-for="(store, index_store) in item.stores"
            :key="index_store"
            class="container_outlined"
          >
            <!-- LISTADO DE TIENDAS POR COMUNIDAD AUTÓNOMA -->
            <m-link @click="showStoreSelected(store.storeInfo)">
              <template v-slot:default>
                <div class="icon_text">
                  <m-icon name="StoreLM48" />
                  <p>
                    {{ store.storeInfo.name }}
                  </p>
                  <div v-if="store.storeInfo.stock > 0" class="units-per-store">
                    <p>
                      {{ store.storeInfo.stock }} {{ $t('ui.label.product.inStock') }}
                      <span class="stock-status-green"></span>
                    </p>
                  </div>
                  <div v-if="store.storeInfo.stock === 0" class="units-per-store">
                    <p>
                      {{ $t('ui.label.product.notAvailableShorten') }}
                      <span class="stock-status-grey"></span>
                    </p>
                  </div>
                  <div v-if="store.storeInfo.stock === -1" class="units-per-store">
                    <p class="units-per-store">{{ $t('ui.label.product.notForSaleShorten') }}</p>
                  </div>
                  <div v-if="store.storeInfo.stock === -2" class="units-per-store">
                    <p class="units-per-store">{{ $t('ui.label.product.uponRequestShorten') }}</p>
                  </div>
                </div>
              </template>
            </m-link>
          </div>
        </template>
      </m-accordion>
    </div>
  </spinner-layout>
</template>

<script>
import { GET_STORES, SET_STORE_SELECTED } from '@/store/actions/stores';
import { GET_PRODUCTS } from '@/store/actions/products';
import { mapActions, mapGetters } from 'vuex';
import LoadingMixin from '@/mixins/LoadingMixin';
import store from '@/store';
import { lsSetSelectedStore, lsStoreSelected, storageAvailable } from '@/utils/localStore';
import { http } from '@/setup/http';
export default {
  name: 'Stores',
  mixins: [LoadingMixin],
  data() {
    return {
      loading: false,
      showModal: false,
      storeSelected: {},
      stores: [],
      storesOrderedByCommunity: [],
      productName: this.$route.params.productName,
      productReference: this.$route.params.productReference,
      productDate: this.$route.params.productDate
    };
  },
  loading: {
    methods: ['fetchStoresData', 'orderStoresByCommunity', 'selectStore'],
    property: 'loading'
  },
  beforeRouteEnter(to, from, next) {
    const { sku, changeStore } = to.params;

    if (changeStore) {
      next();
      return;
    }

    let storeSelected = null;
    if (storageAvailable('localStorage')) {
      storeSelected = lsStoreSelected();
      if (storeSelected) {
        store.dispatch(SET_STORE_SELECTED, storeSelected);
        next({
          name: 'product-detail',
          params: {
            sku,
            storeCode: storeSelected.code
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['getDataStores', 'getDataProducts']),
    listStores() {
      return this.getDataStores('stores');
    },
    skuSelected() {
      return this.getDataProducts('sku');
    }
  },
  async mounted() {
    await this.fetchStoresData().catch(() => {
      this.$router.push({ name: 'Error' });
    });
    await this.orderStoresByCommunity().catch(() => {
      this.$router.push({ name: 'Error' });
    });
    // Si hay producto, se añade el stock de ese producto a las tiendas
    if (this.productName) {

      const data = {"skus": [this.productReference]}

      const response = await http.post("/stores/stock", data, {
      headers: {
        "Content-Type": "application/json"
      }})
      .catch(err => { console.log('Error retrieving POST /stores/stock:' + err.message) })

      const storesStock = response.data.extendedStock[0].stores;

      // UNIFICAR LOS DATOS DEL SERVICIO DE EXTENDED STOCK Y LOS STORES
      this.stores.forEach(store => {
          storesStock.forEach( storeStock => {
            if (store.storeInfo.code === storeStock.code){
              store.storeInfo.stock = storeStock.maxStock;
            }
          })
      });

    }
  },
  methods: {
    ...mapActions([GET_STORES, SET_STORE_SELECTED, GET_PRODUCTS]),
    fetchStoresData() {
      return new Promise((resolve, reject) => {
        this.GET_STORES()
          .then(stores => {
            this.stores = stores;
            resolve();
          })
          .catch(err => {
            console.log(`Error at get stores: ${err.message}`);
            this.$router.push({ name: 'Error' });
          });
      });
    },
    orderStoresByCommunity() {
      return new Promise((resolve, reject) => {
        if (this.stores.length > 0) {
          const comunitys = [];
          const stores = this.stores.slice();
          // Crear array con todas las comunidades
          stores.forEach(store => {
            if (store.storeInfo.comunity && store.storeInfo.code) {
              if (!comunitys.includes(store.storeInfo.comunity))
                comunitys.push(store.storeInfo.comunity);
            }
          });

          comunitys.sort();
          comunitys.forEach(comunity => {
            const comunityObj = {
              comunity,
              stores: []
            };

            stores.forEach(store => {
              if (comunity === store.storeInfo.comunity) {
                comunityObj.stores.push(store);
              }
            });

            comunityObj.stores.sort((a, b) => {
              let nameA = a.storeInfo.name.toUpperCase(); // Ignore case
              let nameB = b.storeInfo.name.toUpperCase(); // Ignore case
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // Names are equal
            });

            this.storesOrderedByCommunity.push(comunityObj);
          });
          resolve();
        } else {
          reject();
        }
      });
    },
    showStoreSelected(store) {
      this.showModal = true;
      this.storeSelected = store;
    },
    async selectStore() {
      const store = this.storeSelected;
      this.showModal = false;
      this.SET_STORE_SELECTED(store);
      lsSetSelectedStore(store);
      const products = await this.getProductsData(this.skuSelected, store.code).catch(err => {
        this.$router.push({ name: 'page-not-found' });
        return;
      });
      if (products.packName) {
        this.$router.push({
          name: 'Products',
          params: {
            group: this.skuSelected,
            storeCode: store.code
          }
        });
      } else {
        this.$router.push({
          name: 'product-detail',
          params: {
            sku: this.skuSelected,
            storeCode: store.code
          }
        });
      }
    },

    getProductsData(sku, storeCode) {
      return new Promise((resolve, reject) => {
        this.GET_PRODUCTS({ sku, storeCode })
          .then(products => {
            resolve(products);
          })
          .catch(err => {
            console.log(`Error at get products: ${err}`);
            reject(err);
          });
      });
    },

    address(store) {
      const { ourStore, street } = store;
      return street ? street : ourStore;
    },
    cityProvince(store) {
      let infoCity = '';

      const { city, province } = store;

      if (city && province) {
        infoCity = city + '-' + province;
      } else {
        infoCity = city || province;
      }

      return infoCity;
    }
  }
};
</script>
<style lang="scss" scoped>

h4 {
  margin: 0 !important;
}

.mc-link {
  text-decoration: none !important;
  width: 100% !important;
  padding: 0.5rem !important;
}

.mc-modal {
  position: relative !important;
  bottom: none;
}

.units-per-store {
  margin-left: auto;
}

.stock-status-green {
  width: 0.75rem;
  height: 0.75rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #46a610;
  display: inline-block;
  vertical-align: middle;
}

.stock-status-grey {
  width: 0.75rem;
  height: 0.75rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: grey;
  display: inline-block;
  vertical-align: middle;
}

.margin-bottom-zero {
  margin-bottom: 0;
  font-weight: 600;
}

.sku-date-info {
  margin-top: 0;
  line-height: 3;
  font-size: 1.25rem;
  font-weight: 400;
}

</style>
